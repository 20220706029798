import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Stepper from "components/stepper/Stepper";
import CourseOverview from "components/courses/CourseOverview";
import useCourse from "hooks/courses/useCourse";
import useCourseForm from "hooks/courses/useCourseForm";
import CourseView from "components/courses/CourseView";
import QuizForm from "components/quiz/QuizForm";
import CourseSummaryForm from "components/courses/summary/CourseSummaryForm";
import MainContent from "components/main-content/MainContent";
import useAuth from "hooks/useAuth";
import TextEditor from "components/textEditor/TextEditor";
import UploadPDF from "components/pdf/UploadPDF";

const ManageCourse = ({ isEditable }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { current, updateCourse, createCourse, deleteCourse } = useCourse({
    id,
  });
  const { currentUser } = useAuth();
  const { errors, FIELDS, values, setFieldValue, handleSubmit } = useCourseForm(
    {
      id,
      course: current || {},
      create: !id ? createCourse : () => {},
      update: id ? updateCourse : () => {},
      currentUser,
    },
  );
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: t("courses.general"),
      content: (
        <CourseOverview
          errors={{
            title: errors[FIELDS.TITLE],
            description: errors[FIELDS.DESCRIPTION],
            images: errors[FIELDS.IMAGES],
            writer: errors[FIELDS.WRITER],
          }}
          overview={{
            title: values[FIELDS.TITLE],
            description: values[FIELDS.DESCRIPTION],
            images: values[FIELDS.IMAGES],
            subject: values[FIELDS.SUBJECT],
            courseClass: values[FIELDS.COURSE_CLASS],
            writer: values[FIELDS.WRITER],
          }}
          setFieldValue={setFieldValue}
          deleteCourse={deleteCourse}
          id={id}
        />
      ),
    },
    {
      id: 2,
      title: "Step 2",
      desc: "Նպատակը",
      content: (
        <div className="pb-20">
          <TextEditor
            isEditable={isEditable}
            setValue={(value) => {
              setFieldValue(FIELDS.GOAL, value);
            }}
            value={values[FIELDS.GOAL]}
            height="100%"
            width="100%"
          />
        </div>
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: "Թեմատիկ պլան",
      content: (
        <div className="pb-20">
          <UploadPDF
            src={values[FIELDS.PLAN]}
            setFile={(file) => setFieldValue(FIELDS.PLAN, file)}
          />
        </div>
      ),
    },
    {
      id: 4,
      title: "Step 4",
      desc: t("courses.lessons"),
      content: (
        <div className="pb-20">
          <MainContent
            selectedItems={values[FIELDS.LESSONS]}
            id={id}
            label={t("courses.lessons")}
            isLoading={false}
            getNextPage={() => {}}
            page={1}
            setPage={() => {}}
            hasMoreItems={false}
            category={values[FIELDS.COURSE_CLASS]}
            setCategory={(value) => setFieldValue(FIELDS.COURSE_CLASS, value)}
            setSelectedItems={(value) => setFieldValue(FIELDS.LESSONS, value)}
            currentUser={currentUser}
          />
        </div>
      ),
    },
    {
      id: 5,
      title: "Step 5",
      desc: "Ամփոփում",
      content: (
        <div className="pb-20">
          <CourseSummaryForm
            currentSummary={values[FIELDS.SUMMARY]}
            setCurrentSummary={(value) => setFieldValue(FIELDS.SUMMARY, value)}
          />
        </div>
      ),
    },
    {
      id: 6,
      title: "Step 6",
      desc: "Հարցաշար",
      content: (
        <div className="pb-20">
          <QuizForm
            currentQuiz={values[FIELDS.QUIZ]}
            setCurrentQuiz={(value) => setFieldValue(FIELDS.QUIZ, value)}
          />
        </div>
      ),
    },
    {
      id: 7,
      title: "Step 7",
      desc: t("courses.review"),
      content: <CourseView isEditable={isEditable} course={values} />,
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isWithErrors={Object.keys(errors).length}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-[90%]">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 6 &&
          !Object.keys(errors).length &&
          values[FIELDS.TITLE] && (
            <div
              onClick={() => {
                if (activeStep === 6) {
                  setActiveStep(activeStep + 1);
                  handleSubmit();
                } else {
                  setActiveStep(activeStep + 1);
                }
              }}
              className={`p-2 bg-opacity-50 cursor-pointer flex justify-end ${
                steps.length !== activeStep ? "opacity-100" : "opacity-0"
              }`}
            >
              <FaChevronRight size={32} color="gray" />
            </div>
          )}
      </div>
    </div>
  );
};

export default ManageCourse;
