import { useFormik } from "formik";
import { eventFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";
import { runFireworks } from "components/confetti";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGE: "image",
  LOCATION: "location",
  MAP: "map",
  TOPICS: "topics",
  ORGANIZERS: "organizers",
  START: "start",
  END: "end",
};

const useEventForm = (props) => {
  const { event, create, update, id } = props;
  const { currentUser } = useAuth();
  const eventForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: event.title || "",
      [FIELDS.IMAGE]: event.image || "",
      [FIELDS.DESCRIPTION]: event.description || "",
      [FIELDS.LOCATION]: event.location || "",
      [FIELDS.MAP]: event.map || null,
      [FIELDS.TOPICS]: event.topics || {
        topics: [],
      },
      [FIELDS.ORGANIZERS]: event.organizers || [],
      [FIELDS.START]: event.start || null,
      [FIELDS.END]: event.end || null,
    },
    enableReinitialize: true,
    validationSchema: eventFormSchema,
    onSubmit: async (values) => {
      let image = "";
      const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.IMAGE] !== "string") {
        image = await mediaUploader(
          values[FIELDS.IMAGE],
          `events/images/${slug}`,
        );
      }

      create({
        ...values,
        image: image || event.image,
        createdAt: Date.now(),
        author: { id: currentUser?.id, displayName: currentUser.displayName },
      });
      update(id, {
        ...values,
        image: image || event.image,
        updatedAt: Date.now(),
      });
      runFireworks();
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = eventForm;

  return {
    eventForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: eventForm.errors,
  };
};

export default useEventForm;
