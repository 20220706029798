import { useFormik } from "formik";
import { courseFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import { runFireworks } from "components/confetti";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGES: "images",
  LESSONS: "lessons",
  SUMMARY: "summary",
  QUIZ: "quiz",
  SUBJECT: "subject",
  COURSE_CLASS: "courseClass",
  GOAL: "goal",
  PLAN: "plan",
  WRITER: "writer",
};

const useCourseForm = (props) => {
  const { currentUser, course, create, update, id } = props;
  const courseForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: course.title || "",
      [FIELDS.IMAGES]: course.images || {},
      [FIELDS.DESCRIPTION]: course.description || "",
      [FIELDS.LESSONS]: course.lessons || [],
      [FIELDS.QUIZ]: course.quiz || {
        questions: [],
        description: "",
        type: "",
      },
      [FIELDS.SUMMARY]: course.summary || {
        points: [],
      },
      [FIELDS.SUBJECT]: course.subject || "",
      [FIELDS.COURSE_CLASS]: course.courseClass || [],
      [FIELDS.GOAL]: course.goal || "",
      [FIELDS.PLAN]: course.plan || "",
      [FIELDS.WRITER]: course.writer || "",
    },
    enableReinitialize: true,
    validationSchema: courseFormSchema,
    onSubmit: async (values) => {
      let posterUrl = "";
      let coverUrl = "";
      let goalUrl = "";
      let planUrl = "";
      const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.IMAGES]?.cover !== "string") {
        coverUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.cover,
          `courses/covers/${slug}`,
        );
      }
      if (typeof values[FIELDS.IMAGES]?.poster !== "string") {
        posterUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.poster,
          `courses/posters/${slug}`,
        );
      }

      if (typeof values[FIELDS.GOAL]) {
        const blob = new Blob([values[FIELDS.GOAL]], { type: "text/plain" });
        const file = new File([blob], `${slug}.txt`, { type: "text/plain" });
        goalUrl = await mediaUploader(file, `courses/goals/${slug}`);
      }

      if (typeof values[FIELDS.PLAN] !== "string") {
        const blob = new Blob([values[FIELDS.PLAN]], {
          type: "application/pdf",
        });
        const file = new File([blob], `${slug}.pdf`, {
          type: "application/pdf",
        });
        planUrl = await mediaUploader(file, `courses/plans/${slug}`);
      }

      create({
        ...values,
        images: { poster: posterUrl, cover: coverUrl },
        author: { id: currentUser?.id, displayName: currentUser.displayName },
        createdAt: Date.now(),
        lessons: values[FIELDS.LESSONS].map(({ id, title, description }) => ({
          id,
          title,
          description,
        })),
        goal: goalUrl,
        plan: planUrl,
      });
      update(id, {
        ...values,
        images: {
          poster: posterUrl || course.images.poster,
          cover: coverUrl || course.images.cover,
        },
        updatedAt: Date.now(),
        lessons: values[FIELDS.LESSONS].map(({ id, title, description }) => ({
          id,
          title,
          description,
        })),
        goal: goalUrl,
        plan: planUrl || course.plan,
      });
      runFireworks();
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = courseForm;

  return {
    courseForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: courseForm.errors,
  };
};

export default useCourseForm;
