import React from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageUploader from "components/image-upload/ImageUploader";

const ArticleOverview = ({ overview, errors, setFieldValue }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="w-3/4 flex flex-col gap-2">
        <TextField
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("articles.title")}
          error={Boolean(errors?.title)}
          helperText={t(errors?.title)}
        />
        <TextField
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("articles.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
        />
        <TextField
          size="small"
          value={overview?.writer || ""}
          onChange={(e) => setFieldValue("writer", e.target.value)}
          fullWidth
          label={t("articles.writer")}
          error={Boolean(errors?.writer)}
          helperText={t(errors?.writer)}
        />
        <div className="grid grid-cols-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("science")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="science"
              />
            }
            label={t("articles.categories.science")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("civilization")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="civilization"
              />
            }
            label={t("articles.categories.civilization")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("culture")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="culture"
              />
            }
            label={t("articles.categories.culture")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("spiritual")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="spiritual"
              />
            }
            label={t("articles.categories.spiritual")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("education")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="education"
              />
            }
            label={t("articles.categories.education")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("language")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="language"
              />
            }
            label={t("articles.categories.language")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("folklore")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value,
                        )
                      : [...(overview?.categories || []), e.target.value],
                  )
                }
                value="folklore"
              />
            }
            label={t("articles.categories.folklore")}
          />
        </div>
      </div>
      <div className="flex v1/2 gap-10">
        <ImageUploader
          text={t("articles.thumbnail")}
          image={overview?.images?.cover || ""}
          setImage={(file, url) =>
            setFieldValue("images", {
              ...overview.images,
              cover: file,
            })
          }
        />
        <ImageUploader
          text={t("articles.cover")}
          image={overview?.images?.thumbnail || ""}
          setImage={(file, url) =>
            setFieldValue("images", {
              ...overview.images,
              thumbnail: file,
            })
          }
        />
      </div>
    </div>
  );
};

export default ArticleOverview;
