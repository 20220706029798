import React from "react";

// import { Navbar, Sidebar, SidebarMobile, Settings } from "..";
import Sidebar from "../sidebar/Sidebar";
import useMainPage from "./useMainPage";
import Navbar from "../navbar/Navbar";
import Loading from "components/common/Loading";
// import SidebarMobile from "../sidebar/SidebarMobile";
// import { UserContext } from "context/UserContext";
import useAuth from "../../hooks/useAuth";
// import SODLoader from "components/loader/SODLoader";
// import SignIn from "pages/sign-in/index";

const MainPageWrapper = ({ children, settings, searchComponent }) => {
  const { sidebar, background } = settings || {};
  // const [isExpanded, setIsExpanded] = useState(true);
  // const { currentUser, isLoading } = useContext(UserContext);
  const { currentUser, isLoading, signOut } = useAuth();
  const {
    isExpanded,
    isOpenSidebarMobile,
    setIsExpanded,
    setIsOpenSidebarMobile,
  } = useMainPage();
  if (isLoading) {
    return <Loading />;
  }
  // const isExpanded = true;

  // return currentUser && !isLoading ? (
  return (
    <div className="flex flex-row relative">
      <div
        className={`relative ${
          sidebar === "hidden" ? "hidden" : "block"
        } overflow-hidden`}
      >
        <Sidebar
          signOut={signOut}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      </div>
      {/* <SidebarMobile
        currentUser={currentUser}
        isExpanded={isOpenSidebarMobile}
        setIsExpanded={setIsOpenSidebarMobile}
      /> */}
      <div className={`dark:bg-main-dark-bg bg-white min-h-screen w-full`}>
        <div
          className={`fixed w-full z-50  bg-main-bg dark:bg-main-dark-bg"
            ${isExpanded ? "md:pr-48" : "md:pr-24"}`}
        >
          <Navbar
            currentUser={currentUser}
            isExpanded={isOpenSidebarMobile}
            setIsExpanded={setIsOpenSidebarMobile}
            showMenuButton={sidebar === "hidden"}
            mode={background === "black" && "dark"}
            searchComponent={searchComponent}
          />

          <div className="overflow-y-auto overflow-x-hidden h-screen">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageWrapper;
