import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Stepper from "components/stepper/Stepper";
import useGroup from "hooks/groups/useGroup";
import GroupOverview from "components/groups/GroupOverivew";
import GroupMembers from "components/groups/GroupMembers";
import GroupView from "components/groups/GroupView";
import useGroupForm from "hooks/groups/useGroupForm";
import useUsers from "hooks/users/useUsers";

const EditGroup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { current, updateGroup, createGroup } = useGroup({ id });
  const { users } = useUsers();
  const { groupForm, FIELDS, values, setFieldValue, handleSubmit } =
    useGroupForm({
      id,
      group: current || {},
      create: !id ? createGroup : () => {},
      update: id ? updateGroup : () => {},
    });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: t("groups.groupEdit.overview"),
      content: (
        <GroupOverview
          overview={{
            title: values[FIELDS.TITLE],
            goal: values[FIELDS.GOAL],
            images: values[FIELDS.IMAGES],
          }}
          setFieldValue={setFieldValue}
        />
      ),
      icon: "",
    },
    {
      id: 2,
      title: "Step 3",
      desc: t("groups.groupEdit.members"),
      content: (
        <GroupMembers
          allUsers={users}
          setFieldValue={setFieldValue}
          members={values[FIELDS.MEMBERS]}
        />
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: t("groups.groupEdit.review"),
      content: <GroupView group={values} />,
    },
    {
      id: 5,
      title: "Step 3",
      desc: t("groups.groupEdit.publish"),
      content: (
        <div className="flex items-center justify-center text-green-600 text-[32px]">
          Հրապարակված է
        </div>
      ),
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-[90%]">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 3 && (
          <div
            onClick={() => {
              if (activeStep === 3) {
                setActiveStep(activeStep + 1);
                handleSubmit();
              } else {
                setActiveStep(activeStep + 1);
              }
            }}
            className={`p-2 bg-opacity-50 cursor-pointer flex justify-end ${
              steps.length !== activeStep ? "opacity-100" : "opacity-0"
            }`}
          >
            <FaChevronRight size={32} color="gray" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditGroup;
