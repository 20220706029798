import { useState, useEffect } from "react";

const useMainPage = () => {
  const isOpenFromLocalStorage =
    localStorage.getItem("isOpenSidebar") === "true";
  const [isExpanded, setIsExpanded] = useState(isOpenFromLocalStorage);

  const [isOpenSidebarMobile, setIsOpenSidebarMobile] = useState(false);

  useEffect(() => {
    localStorage.setItem("isOpenSidebar", isExpanded);
  }, [isExpanded]);

  return {
    isExpanded,
    isOpenSidebarMobile,
    setIsExpanded,
    setIsOpenSidebarMobile,
  };
};

export default useMainPage;
